import React from "react";
import { DATE_TIME_FORMAT } from "../../../app/constants/common";
import { createTableColumn, normalizeConfigForTable, serverToUi } from "../../../app/utils/utilityFunctions";
import { generalTableConfig } from "../../../components/config/generalTableConfig";
import { EmployeeUIContent, TableTimeStampDisplay } from "../../../components/ui/UiUtils";

const inventoryReportViewTableColumns = [
  createTableColumn({
    order: 0,
    id: "part_audit_id",
    label: "id",
    disabledColumnField: true,
    primaryKey: true,
  }),

  createTableColumn({
    order: 1,
    id: "part_audit_id",
    label: "id",
    disabledColumnField: true,
    primaryKey: true,
  }),

  createTableColumn({
    order: 1,
    id: "vhc_id",
    label: "Vehicle ID",
    align: "left",
    disablePadding: true,
  }),
  createTableColumn({
    order: 2,
    id: "part_id",
    label: "Part ID",
    align: "left",
  }),
  createTableColumn({
    order: 4,
    id: "prev_qty",
    label: "Previous Quantity",
  }),
  createTableColumn({
    order: 5,
    id: "new_qty",
    label: "New Quantity",
  }),
  createTableColumn({
    order: 3,
    id: "part_name",
    label: "Part Name",
    align: "left",
  }),
  createTableColumn({
    order: 6,
    id: "ern",
    label: "Updated By",
    align: "left",
    renderContent: (data, searchStr) => <EmployeeUIContent empId={data} searchStr={searchStr} />,
  }),
  createTableColumn({
    order: 7,
    id: "tms",
    label: "Updated At",
    align: "left",
    renderContent: (dateInput, searchStr) => (
      <TableTimeStampDisplay dateInput={dateInput} format={DATE_TIME_FORMAT.UI_FULL_FORMAT} searchStr={searchStr} />
    ),
    renderPDF: (data) => serverToUi(data, DATE_TIME_FORMAT.UI_FULL_FORMAT),
    renderCSV: (data) => serverToUi(data, DATE_TIME_FORMAT.UI_FULL_FORMAT),
  }),
].sort((a, b) => a.order - b.order);

export const inventoryReportTableConfig = normalizeConfigForTable(
  generalTableConfig({
    tableId: "inventory-report-table",
    tableTitle: "Inventory Report",
    tableViewColumns: inventoryReportViewTableColumns,
    preventDisplayOf: {
      selectSortDataByDisplay: false,
      filterOptionsDisplay: false,
      deleteRowsButtonDisplay: true,
    },
    otherDefaultsVariables: {
      paginationTitle: "Rows / Page",
      hideDateSelectorOptions: [4],
      defaultDateSelectedOption: 0,
    },
    paginationRowsOptions: {
      pageOptions: [10, 20, 30],
    },
  })
);
