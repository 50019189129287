import React from "react";
import { DATE_TIME_FORMAT } from "../../../app/constants/common";
import { createTableColumn, normalizeConfigForTable, serverToUi } from "../../../app/utils/utilityFunctions";
import { generalTableConfig } from "../../../components/config/generalTableConfig";
import { InventoryPartsModal } from "../../../components/ui/InventoryPartsModal";
import { TableTimeStampDisplay } from "../../../components/ui/UiUtils";
import { useUpdateVehiclePartsMutation } from "../../../hooks/ApiHooks";
import { RenderQuantityDataCell, RenderSaveButton } from "../InventoryUIComponents";

export const quantityId = "qty";

const partsViewTableColumns = (vehicleId) =>
  [
    createTableColumn({
      order: 1,
      id: "part_id",
      label: "Id",
      align: "left",
      primaryKey: true,
      disablePadding: true,
    }),
    createTableColumn({
      order: 1,
      id: "supplier_id",
      label: "Supplier Id",
      align: "left",
    }),
    createTableColumn({
      order: 2,
      id: "part_nicknm",
      label: "Nick Name",
      align: "left",
    }),
    createTableColumn({
      order: 3,
      id: "part_name",
      label: "Name",
      align: "left",
    }),
    createTableColumn({
      order: 4,
      id: "part_desc",
      label: "Description",
      align: "left",
    }),
    createTableColumn({
      order: 5,
      id: quantityId,
      label: "Quantity",
      alternateValue: "-",
      storeCustomDataOnRender: true,
      renderContent: (dataQuantity, searchStr, currQuantity, onChangeQuantity, _, row) => (
        <RenderQuantityDataCell
          dataQuantity={dataQuantity}
          searchStr={searchStr}
          currQuantity={currQuantity}
          onChangeQuantity={onChangeQuantity}
          partRow={row}
          vehicleId={vehicleId}
        />
      ),
    }),
    createTableColumn({
      order: 6,
      id: "upd_tms",
      label: "Last Modified",
      renderContent: (dateInput, searchStr) => (
        <TableTimeStampDisplay dateInput={dateInput} format={DATE_TIME_FORMAT.UI_FULL_FORMAT} searchStr={searchStr} />
      ),
      renderPDF: (data) => serverToUi(data, DATE_TIME_FORMAT.UI_FULL_FORMAT),
      renderCSV: (data) => serverToUi(data, DATE_TIME_FORMAT.UI_FULL_FORMAT),
    }),
  ].sort((a, b) => a.order - b.order);

export const partsInventoryTableConfig = (vehicleId, deleteHookWithParams) =>
  normalizeConfigForTable(
    generalTableConfig({
      tableId: "parts-inventory-table",
      exportExtraArgs: { vhc_id: vehicleId },
      tableTitle: "Inventory Parts",
      tableViewColumns: partsViewTableColumns(vehicleId),
      preventDisplayOf: {
        tableActionsDisplay: false,
        refetchDataByDisplay: false,
        filterOptionsDisplay: false,
        deleteRowsButtonDisplay: true,
      },
      otherDefaultsVariables: {
        paginationTitle: "Parts / Page",
        newRowTooltipTitle: "Add New Part",
      },
      paginationRowsOptions: {
        pageOptions: [10, 20, 30],
      },
      customFunctions: {
        extraButtonComponent: (props) => <RenderSaveButton vehicleId={vehicleId} customDataId={quantityId} {...props} />,
        deleteHookWithParams,
      },
      addRowButtonNavigateOptions: {
        hasAddRowDialog: true,
        newRowDialogComponent: (props) => (
          <InventoryPartsModal parentEntityId={vehicleId} useApiHook={useUpdateVehiclePartsMutation} modalTitle="Vehicle Part" {...props} />
        ),
      },
    })
  );
