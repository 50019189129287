import {
  Build,
  DeviceHub,
  Flaky,
  Groups,
  ManageAccounts,
  People,
  PrecisionManufacturing,
  PriorityHigh,
  ReceiptLong,
  Sell,
  Tag,
  Task,
  TextFields,
} from "@mui/icons-material";
import { ERROR, INFO, SECONDARY, SUCCESS, WARNING } from "../common";
import { CONFIG_CONSTANTS_OPTIONS } from "./AppSettings";

export const TASK_FIELDS = {
  CUSTOMER: {
    ID: "customer_id",
    CONFIG_ID: "cust_id",
    LABEL: "Customer",
    ALTERNATE: "N/A",
    PLACEHOLDER: "Select Customer...",
    LIST_CONFIG_ID: CONFIG_CONSTANTS_OPTIONS.CUSTOMER,
    START_ICON: <Sell />,
  },
  STORE: {
    ID: "task_store_id",
    CONFIG_ID: "store_id",
    LABEL: "Store",
    ALTERNATE: "N/A",
    PLACEHOLDER: "Select Store...",
    LIST_CONFIG_ID: CONFIG_CONSTANTS_OPTIONS.STORE,
    START_ICON: <ReceiptLong />,
  },
  STORE_TITLE: {
    ID: "store_name",
    LABEL: "Store",
  },
  START_TIME: {
    ID: "exec_time_start",
    LABEL: "Task Start",
    ALTERNATE: "-",
  },
  END_TIME: {
    ID: "exec_time_end",
    LABEL: "Task End",
    ALTERNATE: "-",
  },
  TASK_ASSIGNED_DATE: {
    ID: "task_assign_date",
    LABEL: "Task Assigned Date",
    ALTERNATE: "Not Assigned",
  },
  EQUIPMENT: {
    ID: "task_eq_id",
    CONFIG_ID: "eq_id",
    LABEL: "Equipment",
    ALTERNATE: "N/A",
    PLACEHOLDER: "Select Equipment...",
    LIST_CONFIG_ID: CONFIG_CONSTANTS_OPTIONS.EQUIPMENT,
    START_ICON: <PrecisionManufacturing />,
  },
  CUSTOMER_NUMBER: {
    ID: "cust_rpt_no",
    LABEL: "Customer Report Number",
    ALTERNATE: "N/A",
    PLACEHOLDER: "Enter customer report number...",
    START_ICON: <Tag />,
  },
  TASK: {
    ID: "task_id",
    LABEL: "Task ID",
    ALTERNATE: "N/A",
    PLACEHOLDER: "Enter task ID...",
    START_ICON: <Task />,
  },
  TITLE: {
    ID: "task_title",
    LABEL: "Task Title",
    ALTERNATE: "N/A",
    PLACEHOLDER: "Enter task title...",
    START_ICON: <TextFields />,
  },
  PARENT_ID: {
    ID: "task_par_id",
    LABEL: "Parent Task",
    ALTERNATE: "N/A",
    PLACEHOLDER: "Select Parent Task...",
    LIST_CONFIG_ID: CONFIG_CONSTANTS_OPTIONS.TASKS,
    START_ICON: <DeviceHub />,
  },
  TYPE: {
    ID: "task_type",
    LABEL: "Task Type",
    ALTERNATE: "None",
    PLACEHOLDER: "Select Task Type...",
    LIST_CONFIG_ID: CONFIG_CONSTANTS_OPTIONS.TYPE,
    START_ICON: <Build />,
  },
  PRIORITY: {
    ID: "task_prty",
    LABEL: "Task Priority",
    ALTERNATE: "None",
    PLACEHOLDER: "Select Task Priority...",
    LIST_CONFIG_ID: CONFIG_CONSTANTS_OPTIONS.PRIORITY,
    START_ICON: <PriorityHigh />,
  },
  OWNER: {
    ID: "task_owner",
    CONFIG_ID: "ern",
    LABEL: "Task Owner",
    ALTERNATE: "None",
    PLACEHOLDER: "Select Task Owner...",
    LIST_CONFIG_ID: CONFIG_CONSTANTS_OPTIONS.EMPLOYEES,
    START_ICON: <ManageAccounts />,
  },
  ASSIGNED_EMPLOYEES: {
    ID: "task_assigned_to",
    CONFIG_ID: "ern",
    LABEL: "Task Assigned To",
    ALTERNATE: "No Assigned Employee",
    PLACEHOLDER: "Select Employees...",
    LIST_CONFIG_ID: CONFIG_CONSTANTS_OPTIONS.EMPLOYEES,
    START_ICON: <Groups />,
  },
  EXECUTOR_EMPLOYEES: {
    ID: "task_executors",
    CONFIG_ID: "ern",
    LABEL: "Task Executors",
    ALTERNATE: "No Executor Employees",
    PLACEHOLDER: "Select Employees...",
    LIST_CONFIG_ID: CONFIG_CONSTANTS_OPTIONS.EMPLOYEES,
    START_ICON: <People />,
  },
  STATUS: {
    ID: "task_status",
    LABEL: "Task Status",
    ALTERNATE: "None",
    PLACEHOLDER: "Select Status...",
    LIST_CONFIG_ID: CONFIG_CONSTANTS_OPTIONS.STATUS,
    START_ICON: <Flaky />,
  },
  TAGS: {
    ID: "task_tags",
    CONFIG_ID: "tag_id",
    LABEL: "Task Tags",
    ALTERNATE: "None",
    PLACEHOLDER: "Select Task Tags",
    LIST_CONFIG_ID: CONFIG_CONSTANTS_OPTIONS.TASK_TAGS,
    START_ICON: <Tag />,
  },
  DESCRIPTION: {
    ID: "task_desc",
    LABEL: "Task Description",
    ALTERNATE: "No Description Provided",
    PLACEHOLDER: "task description",
    LIST_CONFIG_ID: CONFIG_CONSTANTS_OPTIONS.PRIORITY,
  },
  PROBLEM: {
    ID: "task_prob",
    LABEL: "Problem Description",
    ALTERNATE: "No Description Provided",
    PLACEHOLDER: "problem description",
  },
  CAUSE: {
    ID: "task_prob_cause",
    LABEL: "Problem Cause Description",
    ALTERNATE: "No Cause Provided",
    PLACEHOLDER: "problem cause description",
  },
  REPAIR: {
    ID: "task_repair_act",
    LABEL: "Repair Action Description",
    ALTERNATE: "No Repair Action Provided",
    PLACEHOLDER: "repair action description",
  },
  RETAIN_PARTS: {
    ID: "retain_parts",
    LABEL: "Technician must return used parts",
    ALTERNATE: "Unknown",
  },
  OVERTIME: {
    ID: "overtime",
    LABEL: "Overtime",
  },
  DATE_INSTALLATION: {
    ID: "date_installation",
    LABEL: "Date Installation",
    ALTERNATE: "-",
  },
  WARRANTY_DATE: {
    ID: "warranty_date",
    LABEL: "Warranty Date",
    ALTERNATE: "-",
  },
  STATE: {
    ID: "state",
    LABEL: "State",
    ALTERNATE: "-",
  },
};

export const TASKS_VALUE_MAPPING = {
  [TASK_FIELDS.PRIORITY.ID]: {
    4: { id: 4, label: "Normal", color: SUCCESS },
    3: { id: 3, label: "Medium", color: INFO },
    2: { id: 2, label: "High", color: WARNING },
    1: { id: 1, label: "Critical", color: ERROR },
  },
  [TASK_FIELDS.TYPE.ID]: {
    QMP: { id: "QMP", label: "QMP", color: SUCCESS },
    INS: { id: "INS", label: "Installation", color: INFO },
    PRJ: { id: "PRJ", label: "Project", color: SECONDARY },
    BRKDN: { id: "BRKDN", label: "Breakdown", color: ERROR },
    OTHR: { id: "OTHR", label: "Others", color: SECONDARY },
    WTDR: { id: "WTDR", label: "Withdrawal", color: WARNING },
    VRP: { id: "VRP", label: "VRP", color: INFO },
    SR: { id: "SR", label: "Site Reece", color: SECONDARY },
    DLVR: { id: "DLVR", label: "Delivery", color: INFO },
    PS: { id: "PS", label: "PM/Sanitation", color: WARNING },
  },
  [TASK_FIELDS.STATUS.ID]: {
    NEW_TASK: { id: "NEW_TASK", label: "New", color: INFO, tooltipLabel: "New Task" },
    ASSIGNED: { id: "ASSIGNED", label: "Assigned", color: INFO, tooltipLabel: "Task Executors Assigned" },
    PDG_START: { id: "PDG_START", label: "Pending", color: WARNING, tooltipLabel: "Pending Start" },
    ONGOING: { id: "ONGOING", label: "Ongoing", color: INFO, tooltipLabel: "Ongoing" },
    PDG_ADMIN_RV: { id: "PDG_ADMIN_RV", label: "Admin Review", color: SECONDARY, tooltipLabel: "Pending Admin Review" },
    PDG_MGR_RV: { id: "PDG_MGR_RV", label: "Manager Review", color: SECONDARY, tooltipLabel: "Pending Manager Review" },
    PDG_FINANCE_RV: { id: "PDG_FINANCE_RV", label: "Finance Review", color: SECONDARY, tooltipLabel: "Pending Finance Review" },
    PDG_CLIENT_FB: { id: "PDG_CLIENT_FB", label: "Client Feedback", color: SECONDARY, tooltipLabel: "Pending Client Feedback" },
    PDG_CLOSE: { id: "PDG_CLOSE", label: "Pending Close", color: ERROR, tooltipLabel: "Pending Close" },
    CLOSED: { id: "CLOSED", label: "Closed", color: SUCCESS, tooltipLabel: "Closed" },
  },
  [TASK_FIELDS.ASSIGNED_EMPLOYEES.ID]: {
    ADMIN: { id: "ADMIN", label: "Admin" },
    MGR: { id: "MGR", label: "Manager" },
    TECH: { id: "TECH", label: "Technician" },
    LEADER: { id: "LEADER", label: "Leader" },
  },
};

export const TAB_OPTIONS = {
  KEY: "active_tasks_tab",
  OPENED_TASKS: {
    ID: "opened-tasks",
    TAB_LABEL: "Opened Tasks",
    TABLE_LABEL: "Opened Tasks",
    FETCH_BY_STATUS_ARRAY: [
      "NEW_TASK",
      "ASSIGNED",
      "PDG_START",
      "ONGOING",
      "PDG_ADMIN_RV",
      "PDG_MGR_RV",
      "PDG_FINANCE_RV",
      "PDG_CLIENT_FB",
      "PDG_CLOSE",
    ],
  },
  COMPLETED_TASKS: {
    ID: "completed-tasks",
    TAB_LABEL: "Completed Tasks",
    TABLE_LABEL: "Completed Tasks",
    FETCH_BY_STATUS_ARRAY: ["CLOSED"],
  },
  ALL_TASKS: {
    ID: "all-tasks",
    TAB_LABEL: "Task Report",
    TABLE_LABEL: "All Tasks",
    FETCH_BY_STATUS_ARRAY: [
      "NEW_TASK",
      "ASSIGNED",
      "PDG_START",
      "ONGOING",
      "PDG_ADMIN_RV",
      "PDG_MGR_RV",
      "PDG_FINANCE_RV",
      "PDG_CLIENT_FB",
      "PDG_CLOSE",
      "CLOSED",
    ],
  },
};
